import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AuthenticationService,
  EnvironmentService,
  ErrorHandlingService,
  SelfService,
} from '@app/core/services';
import {
  APP_LANGUAGE,
  LanguageLocale,
  PRODUCT_NAME,
  getLanguageLocaleCulture,
  getLanguageLocale,
} from '@app/core/utils';
import {
  Language,
  NavigationItem,
  NavigationModule,
  UserInfoDto,
} from '@eon-one/one-ui';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dso-navigation',
  standalone: true,
  imports: [NavigationModule],
  templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnInit {
  @Input() public navigationItems: NavigationItem[] = [];

  readonly #translateService: TranslateService = inject(TranslateService);
  readonly #selfService: SelfService = inject(SelfService);
  readonly #authenticationService: AuthenticationService = inject(
    AuthenticationService
  );
  readonly #onLangChange$ = this.#translateService.onLangChange;
  readonly #environmentService = inject(EnvironmentService);
  readonly #errorHandlingService = inject(ErrorHandlingService);
  readonly #destroy: DestroyRef = inject(DestroyRef);

  public selectedLanguage!: string;
  public readonly productName = PRODUCT_NAME;
  public userInfo!: UserInfoDto;
  public readonly servicePortalBaseUrl: string =
    this.#environmentService.servicePortalUrl;
  public readonly supportedLanguages: Language[] = [
    {
      label: APP_LANGUAGE.en.label,
      value: APP_LANGUAGE.en.localeCulture!,
    },
    {
      label: APP_LANGUAGE.de.label,
      value: APP_LANGUAGE.de.localeCulture!,
    },
  ];

  ngOnInit(): void {
    this.selectedLanguage = getLanguageLocaleCulture(
      this.#translateService.currentLang
    );

    this.#onLangChange$.pipe(takeUntilDestroyed(this.#destroy)).subscribe({
      next: langChangeEvent => {
        this.selectedLanguage = getLanguageLocaleCulture(langChangeEvent.lang);
      },
      error: error => {
        this.#errorHandlingService.handleError(error, {
          shouldRedirect: false,
          showErrorSnackbar: true,
        });
      },
    });

    this.#selfService.getSelf().subscribe({
      next: userInfo => (this.userInfo = userInfo),
      error: error => {
        this.#errorHandlingService.handleError(error, {
          shouldRedirect: true,
        });
      },
    });
  }

  public setLanguage(language: LanguageLocale['localeCulture']): void {
    if (!language) {
      return;
    }
    this.selectedLanguage = getLanguageLocale(language);
    this.#translateService.use(this.selectedLanguage);
  }

  public logout(): void {
    this.#authenticationService.logout();
  }
}
