import { Component, inject, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import { DocumentDto } from 'src/api/dso-portal/generated/models';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import { ConnectionRequestsService } from 'src/api/dso-portal/generated/services';
import {
  DownloadUrlCreatorService,
  ErrorHandlingService,
} from '@app/core/services';
import { IconButtonComponent } from '@app/shared';

@Component({
  selector: 'dso-document',
  standalone: true,
  imports: [TranslateModule, MatIconModule, IconButtonComponent],
  templateUrl: './document.component.html',
  styleUrl: './document.component.scss',
})
export class DocumentComponent {
  @Input({ required: true }) connectionRequestId?: string;
  @Input({ required: true }) document?: DocumentDto;
  #connectionRequestsService = inject(ConnectionRequestsService);
  #downloadUrlCreatorService = inject(DownloadUrlCreatorService);
  #errorHandlingService = inject(ErrorHandlingService);

  public download(): void {
    this.#connectionRequestsService
      .downloadDocument({
        id: this.connectionRequestId!,
        documentId: this.document!.id!,
      })
      .subscribe({
        next: file =>
          this.#downloadUrlCreatorService.create(
            file as Blob,
            this.document!.name!
          ),
        error: error => {
          this.#errorHandlingService.handleError(error, {
            shouldRedirect: false,
            showErrorSnackbar: true,
            msgTranslationIdentifier: 'SNACKBAR.ACTION_ERROR_MESSAGE',
          });
        },
      });
  }
}
