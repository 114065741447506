import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { environment } from './environments';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en-GB';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import { AppComponent } from '@app/app.component';
import { appConfig } from './app';

Sentry.init({
  environment: environment.environment,
  dsn: 'https://b9798da7d15be9ea232667f985baad31@sentry.eon.com/204',
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost'],
});

// eslint-disable-next-line no-console
bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
registerLocaleData(localeDE);
registerLocaleData(localeEN);
