import { ErrorHandlingService } from '@app/core/services';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { catchError } from 'rxjs';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import { ConnectionRequestDto } from 'src/api/dso-portal/generated/models';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import { ConnectionRequestsService } from 'src/api/dso-portal/generated/services';

export const connectionRequestResolver: ResolveFn<
  ConnectionRequestDto
> = route => {
  const requestParamIdentifier = 'id';

  const idFromRoute = route.paramMap.get(requestParamIdentifier);
  const errorHandlingService = inject(ErrorHandlingService);
  return inject(ConnectionRequestsService)
    .getConnectionRequest({
      id: idFromRoute!,
    })
    .pipe(
      catchError(error =>
        errorHandlingService.handleError(error, {
          showErrorSnackbar: false,
          shouldRedirect: true,
        })
      )
    );
};
